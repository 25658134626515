.row.custom-row {
	margin-right: -10px;
	margin-left: -10px;

	> .col,
	> [class*="col-"] {
		padding-right: 10px;
		padding-left: 10px;
	}
}

@media (min-width: 1200px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1210px !important;
	}
}

.container {
	padding: 0 30px;
}

@media (min-width: 576px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		padding: 0 15px;
	}
}
