.currency__table {
	border-collapse: separate;
	border-spacing: 0 6px;

	thead {
		th {
			color: rgba(#202020, 0.5);
			text-transform: uppercase;
			font-weight: 700;
			padding-top: 0;
			padding-bottom: 0;

			.dark-mode & {
				color: rgba($title, 0.5);
			}

			@media (max-width: 767px) {
				font-weight: 500;
				font-size: 0.75rem;
			}
		}
	}

	&--sm {
		th {
			font-size: 0.875rem;
		}
	}

	&--lg {
		th {
			font-size: 1.125rem;
		}
	}

	& th,
	& td {
		vertical-align: middle;

		&:first-child {
			border-top-left-radius: 12px;
			border-bottom-left-radius: 12px;

			@media (max-width: 767px) {
				padding-left: 0;
			}
		}
		&:last-child {
			border-top-right-radius: 12px;
			border-bottom-right-radius: 12px;

			@media (max-width: 767px) {
				padding-right: 0;
			}
		}
	}

	& td {
		@media (max-width: 768px) {
			font-weight: 500;
			font-size: 0.875rem;
		}
	}
}
